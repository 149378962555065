// Here you can add other styles
.bg-gradient-primary {
  background: #39B54A !important;
  background: linear-gradient(45deg, #39B54A 0%, #82C650 100%) !important;
  border-color: #39B54A !important;
}

.bg-gradient-info {
  background: #56C9E0 !important;
  background: linear-gradient(45deg, #24ABC2 0%, #56C9E0 100%) !important;
  border-color: #56C9E0 !important;
}

.bg-gradient-hubs {
  background: #F5B82B !important;
  background: linear-gradient(45deg, #f5b82b 0%, #F5DA2B 100%) !important;
  border-color: #F5B82B !important;
}

.bg-gradient-members {
  background: #894bf5 !important;
  background: linear-gradient(45deg, #6e1fef 0%, #894bf5 100%) !important;
  border-color: #894bf5 !important;
}

.bg-gradient-dark {
  background: #3d4b63 !important;
  background: linear-gradient(45deg, #313c53 0%, #3d4b63 100%) !important;
  border-color: #3d4b63 !important;
}

.finput::placeholder {
  color: #d9d9d9;
}


